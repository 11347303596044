import { addDatadogRumAction } from './datadogRum';
export var FunnelAction;
(function (FunnelAction) {
    FunnelAction["SEARCH"] = "Search";
    FunnelAction["SELECT_HOTEL"] = "Select Hotel";
    FunnelAction["SELECT_ROOM"] = "Select Room";
    FunnelAction["SELECT_ROOM_ERROR"] = "Select Room Error";
    FunnelAction["REFETCH_AVAILABILITY"] = "Refetch Availability";
    FunnelAction["SELECT_FLIGHT"] = "Select Flight";
    FunnelAction["CONTINUE_TO_BOOK"] = "Continue To Book";
    FunnelAction["PROCEED_TO_PAYMENT"] = "Proceed To Payment";
    FunnelAction["SUBMIT_BOOKING"] = "Submit Booking";
    FunnelAction["PAY_BALANCE"] = "Pay Balance";
})(FunnelAction || (FunnelAction = {}));
export function trackFunnelAction(action, tripType, tags) {
    addDatadogRumAction(`HC CTA ${action}`, Object.assign(Object.assign({}, tags), { tripType }));
}
